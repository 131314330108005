


















































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'
import Table from '@/components/Table/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import TipModal from '@/views/common/tip-modal.vue'
import { Select, Option } from 'element-ui'
import { getDicts } from '@/utils/dict'
Vue.use(Select)
Vue.use(Option)
@Component({
  components: {
    Table,
    Pagination,
    TipModal
  }
})
export default class CardKey extends Vue {
  // 区域字典
  areaDicts: any = []
  area = ''
  searchModel = {
    key: 'buildingNo',
    value: null
  }
  listLoading = false

  tableHeader = [
    {
      key: 'machineCardNo',
      name: '卡号',
      width: '20%'
    },
    {
      key: 'phase',
      name: '区域',
      width: '20%'
    },
    {
      key: 'buildingNo',
      name: '房屋号',
      width: '20%'
    },
    {
      key: 'remark',
      name: '备注',
      width: '20%',
      isRemark: true
    },
    {
      key: 'opt',
      name: '操作',
      width: '10%',
      isSpecial: true
    }
  ]
  tableData = [] as any
  layout = 'total, sizes, prev, pager, next, jumper'
  total = 0
  listQuery = {
    page: 1,
    limit: 15
  }
  cardKeyList = [] as any
  isOpenTipModal = false
  // 删除的卡号id
  deleteCardId = ''

  async beforeCreate() {
    // 区域
    this.areaDicts = await getDicts('PHASE')
  }

  mounted() {
    this.getCardKeyList()
  }

  searchFn() {
    this.listQuery.page = 1
    this.getCardKeyList()
  }

  getCardKeyList() {
    this.$api.card
      .getCardKeyList({
        current: this.listQuery.page,
        size: this.listQuery.limit,
        phase: this.area,
        [this.searchModel.key]: this.searchModel.value
      })
      .then((res: any) => {
        if (res.data.success && res.data.code === '200') {
          this.cardKeyList = res.data.data
          this.total = res.data.total
        }
      })
  }

  deleteCard() {
    // 删除
    this.$api.card.deleteCardKey(this.deleteCardId).then((res: any) => {
      if (res.data.success) {
        this.isOpenTipModal = false
        this.$message.success('门禁卡删除成功')
        this.getCardKeyList()
        this.deleteCardId = ''
      }
    })
  }

  toEdit(id: any) {
    this.$router.push({
      path: '/card-key/card-add',
      query: {
        id: id
      }
    })
  }

  toAdd() {
    this.$router.push('/card-key/card-add')
  }

  closeDialog() {
    this.isOpenTipModal = false
  }
}
